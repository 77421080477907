import React from "react";
import "../App.css";
import TorusSdk from "@toruslabs/customauth";
import axios from "axios";

interface IState {
  isLoggedIn: boolean;
  error?: string;
}

interface IProps {}

class RedirectAuth extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isLoggedIn: false };
  }

  async componentDidMount() {
    try {
      const torusdirectsdk = new TorusSdk({
        baseUrl: window.location.origin,
        redirectPathName: "auth",
        enableLogging: true,
        uxMode: "redirect",
        network: "testnet",
      });
      const loginDetails = await torusdirectsdk.getRedirectResult();
      console.log(loginDetails);
      const bla = loginDetails as any;
      const privateKey = bla.result?.privateKey;
      const publicKey = bla.result?.publicAddress;
      console.log("private key", privateKey);
      console.log("private key", publicKey);

      if (!publicKey || !privateKey) throw new Error("No Key");

      axios.defaults.headers.common["X-API-Key"] = "gGiHLg9/vKhct+TEUxO6P999hJSEPJcaaO7RoGTkJio=";

      const baseUrl = "https://staging-v2.bot.bonuz.market";
      // const baseUrl = "https://mende-bot-staging-v2-ngdpjbbjuq-uc.a.run.app";

      const url = `${baseUrl}/api/AuthUser?token=${bla.args.jwtParams.usertoken}&authenticated=true&walletAddress=${bla.result.publicAddress}`;

      axios
        .get(url)
        .then(() => {
          window.close();

          this.setState({ isLoggedIn: true });
        })
        .catch((error) => this.setState({ error }));
    } catch (error) {
      if (typeof error === "string") {
        this.setState({ error });
      } else if (error instanceof Error) {
        this.setState({ error: error.message });
      }
    }
  }

  render() {
    const { isLoggedIn, error } = this.state;
    return (
      <div className="App">
        {!isLoggedIn && (
          <div style={{ textAlign: "center" }}>
            Please wait a few seconds
            <br/>
            until we log you in.
          </div>
        )}
        {isLoggedIn && <>Please close the browser.</>}
        {error && (
          <>
            <h1>Error occurred</h1>
            <p>{error}</p>
          </>
        )}
      </div>
    );
  }
}

export default RedirectAuth;

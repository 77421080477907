/* eslint-disable class-methods-use-this */
import React from "react";
import TorusSdk, { UX_MODE, TorusLoginResponse } from "@toruslabs/customauth";

import {
  verifierMap,
  GITHUB,
  TWITTER,
  APPLE,
  AUTH_DOMAIN,
  EMAIL_PASSWORD,
  HOSTED_EMAIL_PASSWORDLESS,
  HOSTED_SMS_PASSWORDLESS,
  LINE,
  LINKEDIN,
  WEIBO,
  COGNITO,
  COGNITO_AUTH_DOMAIN,
  REDDIT,
  GOOGLE,
  DISCORD,
  FACEBOOK,
} from "./constants";

const getTokenParameter = () => new URL(window.location.href).searchParams.get("token");

interface IState {
  torusdirectsdk: TorusSdk | null;
  loginHint: string;
  loginResponse?: TorusLoginResponse | null;
}

interface IProps {}

class HomePage extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      torusdirectsdk: null,
      loginHint: "",
      loginResponse: null,
    };
  }

  componentDidMount = async () => {
    try {
      const torusdirectsdk = new TorusSdk({
        baseUrl: window.location.origin,
        // user will be redirect to auth page after login
        redirectPathName: "auth",
        enableLogging: true,
        uxMode: UX_MODE.REDIRECT,
        network: "testnet",
      });
      await torusdirectsdk.init({ skipSw: true });

      this.setState({ torusdirectsdk });
    } catch (error) {
      console.error(error, "mounted caught");
    }
  };

  _loginToConnectionMap = (): Record<string, any> => {
    return {
      [EMAIL_PASSWORD]: { domain: AUTH_DOMAIN },
      [HOSTED_EMAIL_PASSWORDLESS]: {
        domain: AUTH_DOMAIN,
        verifierIdField: "name",
        connection: "",
        isVerifierIdCaseSensitive: false,
      },
      [HOSTED_SMS_PASSWORDLESS]: { domain: AUTH_DOMAIN, verifierIdField: "name", connection: "" },
      [APPLE]: { domain: AUTH_DOMAIN },
      [GITHUB]: { domain: AUTH_DOMAIN },
      [LINKEDIN]: { domain: AUTH_DOMAIN },
      [TWITTER]: { domain: AUTH_DOMAIN },
      [WEIBO]: { domain: AUTH_DOMAIN },
      [LINE]: { domain: AUTH_DOMAIN },
      [COGNITO]: { domain: COGNITO_AUTH_DOMAIN, identity_provider: "Google", response_type: "token", user_info_endpoint: "userInfo" },
      [REDDIT]: { domain: AUTH_DOMAIN, connection: "Reddit", verifierIdField: "name", isVerifierIdCaseSensitive: false },
    };
  };

  login = async (selectedVerifier: string) => {
    const { torusdirectsdk } = this.state;

    try {
      const jwtParams = this._loginToConnectionMap()[selectedVerifier] || { usertoken: getTokenParameter() };
      const { typeOfLogin, clientId, verifier } = verifierMap[selectedVerifier];
      // in redirect mode, login result will be handled in redirect page
      // (Check auth.tsx file)
      await torusdirectsdk?.triggerLogin({
        typeOfLogin,
        verifier,
        clientId,
        jwtParams,
      });
    } catch (error) {
      console.error(error, "login caught");
    }
  };

  render() {
    const { loginResponse } = this.state;

    if (loginResponse) console.log("login Response", loginResponse);

    const isLoggedIn = false;
    const isLoading = false;
    const onClickLogin = async (selectedVerifier: string) => {
      await this.login(selectedVerifier);
    };

    return (
      <div className="container">
        {isLoggedIn && <>Please close the browser.</>}

        {!isLoggedIn && !isLoading && (
          <>
            <div className="logo-container">
              <img className="logo" src="/res/bonuz.png" />
            </div>
            <hr />
            <div className="content">
              <div className="description">Choose your preferred way to login.</div>
              <button className="primary">Continue with Bonuz</button>

              <div className="socials">
                <img src="/res/google.png" onClick={() => onClickLogin(GOOGLE)} />
                <img src="/res/apple.png" onClick={() => onClickLogin(APPLE)} />
                <img src="/res/discord.png" onClick={() => onClickLogin(DISCORD)} />
                <img src="/res/facebook.png" onClick={() => onClickLogin(FACEBOOK)} />
                <img src="/res/telegram.png" onClick={() => onClickLogin(TWITTER)} />
              </div>
            </div>
            <div className="footer">
              <img src="/res/metamask.png" />
              <img src="/res/binance_wallet.png" />
              <img src="/res/phantom.png" />
              <img src="/res/sollet.png" />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default HomePage;
